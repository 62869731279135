<template>
  <div class="main">
    <div class="main__background" id="top">
        <img class="main__background--img" src="../../assets/textures/azul.png" alt="paint" />
    </div>
    <div class="main__header">
      <h2 class="main__header--title">FAMILIA</h2>
    </div>
    <div class="main__gallery">
      <div class="gallery__content">
        <img class="gallery__content--img" v-for="(image, i) in pageOfItems" :src="image" :key="i" @click="index = i">
      </div>
    </div>
    <div class="main__pagination">
      <a href="#top">
        <jw-pagination :items="images" @changePage="onChangePage"></jw-pagination>
      </a>
    </div>
    <vue-gallery-slideshow :images="pageOfItems" :index="index" @close="index = null"></vue-gallery-slideshow>
    <div class="main__footer">
      <Footer></Footer>
    </div>
  </div>
</template>

<script>
import VueGallerySlideshow from 'vue-gallery-slideshow';
import Footer from '../../components/Footer.vue';

/**IMAGENES */
import F2 from '../../assets/images/Fotografía/Familia/2.jpg';
import F1 from '../../assets/images/Fotografía/Familia/1.jpg';
import F3 from '../../assets/images/Fotografía/Familia/3.jpg';
import F4 from '../../assets/images/Fotografía/Familia/4.jpg';
import F5 from '../../assets/images/Fotografía/Familia/5.jpg';
import F6 from '../../assets/images/Fotografía/Familia/6.jpg';
import F7 from '../../assets/images/Fotografía/Familia/7.jpg';
import F8 from '../../assets/images/Fotografía/Familia/8.jpg';
import F9 from '../../assets/images/Fotografía/Familia/9.jpg';
import F10 from '../../assets/images/Fotografía/Familia/10.jpg';
import F11 from '../../assets/images/Fotografía/Familia/11.jpg';
import F12 from '../../assets/images/Fotografía/Familia/12.jpg';
import F13 from '../../assets/images/Fotografía/Familia/13.jpg';
import F14 from '../../assets/images/Fotografía/Familia/14.jpg';
import F15 from '../../assets/images/Fotografía/Familia/15.jpg';
import F16 from '../../assets/images/Fotografía/Familia/16.jpg';
import F17 from '../../assets/images/Fotografía/Familia/17.jpg';
import F18 from '../../assets/images/Fotografía/Familia/18.jpg';
import F19 from '../../assets/images/Fotografía/Familia/19.jpg';
import F20 from '../../assets/images/Fotografía/Familia/20.jpg';
import F21 from '../../assets/images/Fotografía/Familia/21.jpg';
import F22 from '../../assets/images/Fotografía/Familia/22.jpg';
import F23 from '../../assets/images/Fotografía/Familia/23.jpg';
import F24 from '../../assets/images/Fotografía/Familia/24.jpg';
import F25 from '../../assets/images/Fotografía/Familia/25.jpg';
import F26 from '../../assets/images/Fotografía/Familia/26.jpg';
import F27 from '../../assets/images/Fotografía/Familia/27.jpg';
import F28 from '../../assets/images/Fotografía/Familia/28.jpg';
import F29 from '../../assets/images/Fotografía/Familia/29.jpg';
import F30 from '../../assets/images/Fotografía/Familia/30.jpg';
import F31 from '../../assets/images/Fotografía/Familia/31.jpg';
import F32 from '../../assets/images/Fotografía/Familia/32.jpg';
import F33 from '../../assets/images/Fotografía/Familia/33.jpg';
import F34 from '../../assets/images/Fotografía/Familia/34.jpg';
import F35 from '../../assets/images/Fotografía/Familia/35.jpg';
import F36 from '../../assets/images/Fotografía/Familia/36.jpg';
import F37 from '../../assets/images/Fotografía/Familia/37.jpg';
import F38 from '../../assets/images/Fotografía/Familia/38.jpg';
import F39 from '../../assets/images/Fotografía/Familia/39.jpg';
import F40 from '../../assets/images/Fotografía/Familia/40.jpg';
import F41 from '../../assets/images/Fotografía/Familia/41.jpg';
import F42 from '../../assets/images/Fotografía/Familia/42.jpg';
import F43 from '../../assets/images/Fotografía/Familia/43.jpg';

export default {
    data () {
      return {
        images: [
          F1,F2,F3,F4,F5,F6,F7,F8,F9,F10,
          F11,F12,F13,F14,F15,F16,F17,F18,F19,F20,
          F21,F22,F23,F24,F25,F26,F27,F28,F29,F30,
          F31,F32,F33,F34,F35,F36,F37,F38,F39,F40,
          F41,F42,F43
        ],
        pageOfItems: [],
        index: null
      }
    },
    methods: {
      onChangePage(pageOfItems) {
        this.pageOfItems = pageOfItems;
      }
    },
    components: {
      VueGallerySlideshow,
      Footer
    } 
}
</script>

<style scoped>
.main__background {
  position: relative;
}
.main__background--img {
  width: 100%;
  height: 570px;
  object-fit: cover;
}
.main__header {
  width: 100%;
  position: absolute;
  color: var(--txt-color);
  text-align: center;
  top: 250px;
  margin: auto;
}
.main__header--title{
  font-family: 'Yeseva One';
  font-size: 70px;
  padding: 9px 0px;
  margin: 2px;
}
.main__header--subtitle{
  font-family: var(--font);
  font-weight: 500;
  padding: 9px 0px;
  line-height: 28px;
  margin: 2px;
}
.main__pagination{
  margin-top: 50px;
  text-align: center;
}
.main__gallery{
  margin-top: -100px;
}
.gallery__content{
  position: relative;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.gallery__content--img{
  width: 500px;
  height: 500px;
  object-fit: cover;
  margin: 20px;
}
@media (max-width: 800px){
  .main__header--title{
    font-size: 40px !important;
  }
  .gallery__content--img {
    width: 200px;
    height: 200px;
  }
}
</style>